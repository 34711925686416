@import "./colors";
@import "./typography";
@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $chat-font;
}
/* *****LOADING PAGE***** */
@keyframes loading-animation {
  0% {
    opacity: 1;
    background-color: $main-color;
  }
  50% {
    opacity: 0.7;
    background-color: $white-color;
  }
  100% {
    opacity: 1;
    background-color: #306c82;
  }
}
@keyframes loading-line {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
$gray: #bbb;
/*  */
.loading-page {
  background-color: $dark-gray;
  @include full-width-height;
  @include chat-app-flex;
  flex-direction: column;
  position: fixed;
  gap: 10px;
  left: 0;
  top: 0;
  z-index: 900;
  display: none;
  overflow: hidden;
  &::after {
    @include after-background;
    z-index: 950;
    background: linear-gradient(45deg, #115169d9, #0d4052b4);
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.1;
    filter: grayscale(100%);
    object-position: center;
    z-index: 10;
  }
}

.loading-page-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  text-align: center;
}
.loading-title {
  font-size: 1.4rem;
  color: $white-color;
  h1 {
    font-family: $main-font;
  }
}

.loading-container {
  @include chat-app-flex;
  z-index: 999;
  margin-top: 10px;
  gap: 5px;
}

.box {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $white-color;
  &:first-of-type {
    animation: loading-animation 2s 1s ease infinite;
  }
  &:nth-of-type(2) {
    animation: loading-animation 2s 1.4s ease infinite;
  }
  &:nth-of-type(3) {
    animation: loading-animation 2s 1.8s ease infinite;
  }
  &:nth-of-type(4) {
    animation: loading-animation 2s 2.2s ease infinite;
  }
  &:nth-of-type(5) {
    animation: loading-animation 2s 2.6s ease infinite;
  }
}

.loading-line {
  animation: loading-line 2s ease forwards;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 5px;
  background-color: $white-color;
}

/* *****MAIN***** */
main {
  @include full-width-height;
}

/* *****CHAT APP SECTION***** */
.section-text-app {
  @include full-width-height;
  position: relative;
  .chat-navigation {
    position: absolute;
    top: 10px;
    left: 50%;
    @include chat-app-flex;
    width: fit-content;
    height: 6vh;
    transform: translateX(-50%);
    border-radius: 30px 30px 30px 5px;
    background-color: $main-color;
    z-index: 20;
    padding: 5px 20px;
    color: $white-color;
    transition: 0.5s;
    h1 {
      font-size: 1.2rem;
      font-family: $main-font;
      margin-left: 4px;
      i {
        color: $white-color;
        margin-right: 2px;
      }
    }
    &:hover {
      opacity: 0.9;
      padding: 5px 25px;
    }
  }
}

.online-icon {
  display: inline-block;
  width: 20px;
  filter: invert(1);
  fill: #fff;
  margin-right: 2px;
}

.online-users {
  @include chat-app-flex;
  position: absolute;
  left: 0;
  top: 10px;
  height: 6vh;
  padding: 5px 15px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  background-color: $main-color;
  z-index: 20;
  font-size: 1.4rem;
  border: none;
  color: $white-color;
  transition: 0.5s;
  h4 {
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
  }
  span {
    display: none;
  }
  &:hover {
    opacity: 0.9;
    padding: 10px 17.5px;
  }
}

/* ****NIGHT & LIGHT MODE BUTTON */
.night-mode {
  @include chat-app-flex;
  position: absolute;
  right: 0;
  top: 10px;
  height: 6vh;
  padding: 5px 15px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  background-color: $main-color;
  z-index: 20;
  font-size: 1.4rem;
  border: none;
  color: $icon-color;
  transition: 0.5s;
  span {
    display: none;
  }
  &:hover {
    opacity: 0.9;
    padding: 10px 17.5px;
  }
}

/* *****MAIN BACKGROUND***** */
.desktop-bg {
  display: none;
}

/* *****CHAT APP BACKGROUND***** */
.chat-app-img {
  @include full-width-height;
  bottom: 0;
  position: relative;
  &::after {
    @include after-background;
    background: linear-gradient(#ffffffea, #ffffffd3);
    .chat-img {
      width: 100%;
      height: 100%;
      opacity: 0.1;
      object-fit: cover;
      object-position: center;
    }
  }
}

/* *****MESSAGES (li)***** */
.messages {
  bottom: 10vh;
  position: absolute;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
  word-wrap: break-word;
  .list {
    padding-right: 10px;
    width: 100%;
    list-style: none;
    max-height: 80vh;

    z-index: 1;

    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: $main-color $darkMode-color;
    &::-webkit-scrollbar {
      width: 6px;

      margin-left: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #aaa;
      border-radius: 4px;
    }

    .textMessage {
      text-align: right;
      margin: 10px;
      margin-left: 30%;
      width: 70%;
      .message-box {
        @include chat-app-flex;
        justify-content: flex-end;
        .user {
          font-weight: 500;
          font-size: 0.8rem;
          color: $main-color;
        }
        .message-profile {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid $main-color;
          margin-left: 5px;
        }
      }
      .message {
        width: fit-content;
        max-width: 60%;
        background: linear-gradient(45deg, #4777a1d3, $main-color);
        display: flex;
        flex-direction: column;
        width: fit-content;
        text-align: left;
        color: $white-color;
        padding: 10px;
        border-radius: 25px 25px 5px 25px;
        margin: 2px 5px 0px auto;
        font-size: 1.2rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        span {
          font-weight: 400;
          color: #ddd;
          margin: 6px 0px 0px auto;
          font-size: 0.7rem;
        }
      }
    }
  }
}

/* -----SENDED MESSAGE----  */
.sended-message {
  text-align: left;
  margin: 10px;
  margin-right: 30%;
  width: 70%;
  .message-box {
    @include chat-app-flex;
    justify-content: flex-start;
    .user {
      font-weight: 500;
      font-size: 0.8rem;
      color: $gray;
    }
    .message-profile {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $gray;
      margin-left: 5px;
    }
  }
  .message {
    width: fit-content;
    max-width: 60%;
    background: linear-gradient(45deg, #bbb, #ccc);
    display: flex;
    flex-direction: column;
    width: fit-content;
    text-align: left;
    color: #777;
    padding: 10px;
    border-radius: 25px 25px 25px 5px;
    margin: 2px auto 0px 5px;
    font-size: 1.2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    span {
      font-weight: 400;
      color: #888;
      margin: 6px 0px 0px auto;
      font-size: 0.7rem;
    }
  }
}

/* *****TYPE & SEND MESSAGES + EMOJI***** */
.chat-box {
  z-index: 10;
  position: absolute;
  @include chat-app-flex;
  justify-content: space-around;
  bottom: 15px;
  left: 0;
  width: 95%;
  margin: 0px 2.5%;
  padding: 5px 10px;
  border-radius: 50px;
  background: $main-color;
  input {
    flex-grow: 2;
    display: flex;
    border: none;
    border-radius: 30px;
    padding: 15px 15px;
    font-size: 1rem;
    resize: none;
    color: $white-color;
    background: none;
    white-space: normal;
    &::placeholder {
      color: $white-color;
      font-size: 1.2rem;
      padding-top: 6px;
    }
    &:focus {
      outline-color: #ffffff00;
    }
  }
}

.chat-btn {
  padding-top: 3px;
  background: none;
  border: none;
  margin: 0 10px;
  font-size: 1.6rem;
  color: $white-color;
  transition: 0.5s;
  &:hover {
    color: $icon-color;
  }
}

/* EMOJIS */
.emoji-picker-container {
  position: absolute;
  opacity: 0.9;
  bottom: 6vh;
  left: -4%;
  border-radius: 30px;
  overflow-y: auto;
  transform: scale(0.8);
  background-color: $white-color;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px #0000001a;
  z-index: 100;
}

/* *****DARKMODE***** */
.darkmode {
  z-index: 20;
  &::after {
    @include after-background;
    background: linear-gradient(#06261fd7, #0a0a0ad2);
  }
  .chat-box,
  .chat-navigation,
  .night-mode {
    z-index: 20;
    background: $darkMode-color;
  }
  .online-users {
    background: $darkMode-color;
  }
  .messages {
    .list {
      .textMessage {
        .message-box {
          .user {
            color: $white-color;
          }
          .message-profile {
            border: 2px solid $darkMode-color;
          }
        }
        .message {
          background: linear-gradient(to right, $darkMode-color, #2b927cd7);
          color: #fff;
          span {
            color: $gray;
          }
        }
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */
/* TABLET */
@media only screen and (min-width: 850px) {
  main {
    display: grid;
    grid-template-columns: 2.5fr 1.5fr;
    overflow: hidden;
  }
  .desktop-bg {
    height: 100vh;
    display: block;
    position: relative;
    box-shadow: 0px 0px 10px 5px #0b263e62;
    z-index: 5;
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      opacity: 0.2;
      filter: grayscale(100%);
    }
    h1 {
      position: absolute;
      font-size: 3rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      font-family: $main-font;
      color: $white-color;
      transition: linear all 0.7s;
      &:hover {
        font-size: 3.2rem;
      }
    }
    &::after {
      @include after-background;
      height: 100%;
      background: linear-gradient(45deg, #115169d9, #0d4052b4);
    }
  }

  .darkmode {
    .desktop-bg {
      box-shadow: 0px 0px 10px 5px #0f3a283a;
      &::after {
        @include after-background;
        height: 100%;
        background: linear-gradient(-45deg, #135c4cd7, #0a0a0aaf);
      }
      h1 {
        color: $white-color;
      }
    }
  }
  .section-text-app,
  .chat-app-img {
    @include full-width-height;
  }

  /* *****MAIN BACKGROUND LINKS***** */
  .bottom-links {
    position: absolute;
    width: 100%;
    left: 10px;
    bottom: 15px;
    z-index: 10;
    a {
      width: fit-content;
      text-decoration: none;
      font-size: 1rem;
      background-color: #22222273;
      border-radius: 30px;
      margin: 0 2px;
      color: $white-color;
      padding: 5px 15px;
      transition: all 0.5s;
      &:hover {
        padding: 5px 20px;
        background-color: #ffffff66;
        color: $dark-gray;
      }
    }
  }
}

@mixin chat-app-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin full-width-height {
  width: 100%;
  height: 100vh;
}

@mixin after-background {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}
